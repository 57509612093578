import React, { useState, useEffect } from 'react';
import Image from "next/image";

import Link from 'next/link';
import axios from "axios";

function Services() {

    const [services, setServices] = useState<any>([]);

    const fetchServices = async () => {
        try {
            const result = await axios.get(`${process.env.admin_url}/api/services?sort=order%3Aasc&populate=*`);
            setServices(result?.data?.data);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect( () => { fetchServices(); }, []);

    return (
        <>
            <div className=' grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 md:mt-6'>
                {
                    services.map((service: any, index: number) => (
                        <div key={index} className="mx-auto my-2 md:my-0 flex flex-col border border-gray-300 bg-white rounded-xl shadow-sm shadow-slate-300/60 py-12 text-center items-center">
                            <div className='max-h-[150px] min-h-[150px]'>
                                <Image  src={process.env.admin_url + service?.attributes?.image?.data?.attributes?.url} width={150} height={150} alt={service?.attributes?.title} />
                            </div>
                            <div className="p-4 pb-0 flex flex-col">
                                <h3 className="text-[18px] text-primary-500 font-montserratB pt-4">{service?.attributes?.title}</h3>
                                <p className="text-primary-500 py-6 text-body-mbl md:text-body-mbl ">{service?.attributes?.short_description}</p>
                            </div>
                            <div className='justify-items-end mt-auto w-full px-5'>
                                <Link href={`${process?.env?.app_url}/services/${service?.attributes?.slug}`} passHref>
                                    <button
                                        className="w-full flex items-center justify-center whitespace-nowrap px-4 py-2 border border-transparent rounded-full shadow-sm text-h3 text-base font-montserratB text-white bg-btn-gradient"
                                        onClick={() => {}}
                                    >
                                        Learn More
                                    </button>
                                </Link>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default Services;


// description: 'We bring you an effective and user-friendly website solution. We develop websites for businesses that are tailored precisely to their needs. Every business is different and we make every effort to personalize our services in order to meet your specifications.',